<template>

    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <Sidenav :sidenav="sidenav" />
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                <iframe src="http://localhost/etiquetas"
                                    style="border: 0px; height: 100dvh; width: 100%"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script>
import Header from './Header.vue';
import Sidenav from './Sidenav.vue';

export default {
    name: 'QRScanner',
    props: {
        user: Object,
        sidenav: Boolean,
    },
    components: {
        Header,
        Sidenav,
    },
    data() {
        return {

        };
    },
};
</script>

<style lang="scss" scoped>
select {
    margin-bottom: 10px;
    padding: 5px;
    font-size: 1rem;
}

.result {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}
</style>
