<template>
    <div class="left-sidenav" v-if="sidenav">
        <ul class="metismenu left-sidenav-menu" id="side-nav">
            <li class="menu-title">Menú</li>

            <li :class="$route.path == '/' ? 'active' : ''">
                <router-link to="/"><i class="fa-duotone fa-house-chimney"></i><span>Inicio</span></router-link>
            </li>
        </ul>
        <ul class="metismenu left-sidenav-menu" id="side-nav">

            <li class="menu-title">Menú</li>

            <li :class="$route.path == '/clientes' ? 'active' : ''">
                <router-link to="/clientes"><i class="fa-duotone fa-users"></i><span>Clientes</span></router-link>
            </li>

            <li :class="$route.path == '/nuevoCliente' ? 'active' : ''">
                <router-link to="/nuevoCliente"><i class="fa-duotone fa-user-plus"></i><span>Nuevo
                        cliente</span></router-link>
            </li>

            <li :class="$route.path == '/pedidos' ? 'active' : ''">
                <router-link to="/pedidos"><i class="fa-duotone fa-square-list"></i><span>Pedidos</span></router-link>
            </li>

            <li :class="$route.path == '/listadoPendientes' ? 'active' : ''">
                <router-link to="/listadoPendientes"><i class="fa-duotone fa-hourglass-half"></i><span>Listado de
                        pendientes</span></router-link>
            </li>

            <li :class="$route.path == '/almacen' ? 'active' : ''">
                <router-link to="/almacen"><i class="fa-duotone fa-boxes-stacked"></i><span>Almacén</span></router-link>
            </li>

            <li :class="$route.path == '/avisos' ? 'active' : ''">
                <router-link to="/avisos"><i class="fa-duotone fa-megaphone"></i><span>Enviar avisos</span></router-link>
            </li>

            <li :class="$route.path == '/qr' ? 'active' : ''">
                <router-link to="/qr"><i class="fa-duotone fa-solid fa-qrcode"></i><span>Escanear QR</span></router-link>
            </li>

            
            <li :class="$route.path == '/imprimir' ? 'active' : ''">
                <router-link to="/imprimir"><i class="fa-duotone fa-solid fa-print"></i><span>Imprimir etiquetas</span></router-link>
            </li>
        </ul>

        <ul class="metismenu left-sidenav-menu" id="side-nav">

            <li class="menu-title">Gestión</li>

            <li :class="$route.path == '/configuracion' ? 'active' : ''">
                <router-link to="/configuracion"><i class="fa-duotone fa-gear"></i><span>Configuración</span></router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'PaginaInicio',
    props: {
        user: Object,
        sidenav: Boolean,
    },
    data() {
        return {
            activeSide: true
        }
    },
    beforeMount() {

    },
    methods: {

    }
}
</script>

<style lang="scss" scoped></style>