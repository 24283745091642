import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import './assets/awesome/css/all.css'; // Importa el archivo CSS de FontAwesome
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import PaginaInicio from './components/PaginaInicio.vue';
import PaginaLogin from './components/PaginaLogin.vue';
import PaginaClientes from './components/PaginaClientes.vue';
import PaginaCliente from './components/PaginaCliente.vue';
import NuevoCliente from './components/NuevoCliente.vue';
import ListadoPendientes from './components/ListadoPendientes.vue';
import PaginaAlmacen from './components/PaginaAlmacen.vue';
import EnviarAvisos from './components/EnviarAvisos.vue';
import PaginaConfiguracion from './components/PaginaConfiguracion.vue';
import PaginaPagos from './components/PaginaPagos.vue';
import PaginaPedidos from './components/PaginaPedidos.vue';
import QRScanner from './components/QRScanner.vue';
import PaginaImprimir from './components/PaginaImprimir.vue';
import axios from 'axios';

// Agregar un interceptor para manejar errores de manera global
axios.interceptors.response.use(
  response => response,
  error => {
    console.error('Error en la solicitud:', error);
    return Promise.reject(error);
  }
);



const routes = [
  {
    path: '/',
    component: PaginaInicio,
    meta: { title: 'Horno Goimar • Inicio' },
  },
  {
    path: '/login',
    component: PaginaLogin,
    meta: { title: 'Horno Goimar • Login' },
  },
  {
    path: '/clientes',
    component: PaginaClientes,
    meta: { title: 'Horno Goimar • Clientes' },
  },
  {
    path: '/cliente/:id',
    component: PaginaCliente,
    meta: { title: 'Horno Goimar • Cliente' },
  },
  {
    path: '/nuevoCliente',
    component: NuevoCliente,
    meta: { title: 'Horno Goimar • Nuevo cliente' },
  },
  {
    path: '/listadoPendientes',
    component: ListadoPendientes,
    meta: { title: 'Horno Goimar • Listado de pendientes' },
  },
  {
    path: '/almacen',
    component: PaginaAlmacen,
    meta: { title: 'Horno Goimar • Almacén' },
  },
  {
    path: '/avisos',
    component: EnviarAvisos,
    meta: { title: 'Horno Goimar • Enviar avisos' },
  },
  {
    path: '/configuracion',
    component: PaginaConfiguracion,
    meta: { title: 'Horno Goimar • Configuración' },
  },
  {
    path: '/pagos',
    component: PaginaPagos,
    meta: { title: 'Horno Goimar • Historial de pagos' },
  },
  {
    path: '/pedidos',
    component: PaginaPedidos,
    meta: { title: 'Horno Goimar • Pedidos' },
  },
  {
    path: '/qr',
    component: QRScanner,
    meta: { title: 'Horno Goimar • QR' },
  },
  {
    path: '/imprimir',
    component: PaginaImprimir,
    meta: { title: 'Horno Goimar • Imprimir' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
  devtools: false,
});

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.config.globalProperties.$axios = axios;
app.use(router);
app.use(ElementPlus);

app.mount('#app');
