<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <Sidenav :sidenav="sidenav" />
                <div class="page-content">
                    <div class="container-fluid">
                        <select v-model="selectedDeviceId" @change="initScanner">
                            <option v-for="device in videoInputDevices" :key="device.deviceId" :value="device.deviceId">
                                {{ device.label || `Cámara ${device.deviceId}` }}
                            </option>
                        </select>
                        <video id="preview" style="width: 100%; max-height: 300px; border: 2px solid #ccc;"></video>
                        <div v-if="decodedContent" class="result mt-3">
                            <p>Contenido escaneado: <strong>{{ decodedContent }}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script>
import Header from './Header.vue';
import Sidenav from './Sidenav.vue';
import { BrowserMultiFormatReader } from '@zxing/browser';
import Swal from 'sweetalert2';

export default {
    name: 'QRScanner',
    props: {
        user: Object,
        sidenav: Boolean,
    },
    components: {
        Header,
        Sidenav,
    },
    data() {
        return {
            decodedContent: null,
            videoInputDevices: [],
            selectedDeviceId: null,
            codeReader: null,
            activeStream: null,
            loading: false,
        };
    },
    mounted() {
        this.getVideoInputDevices();
    },
    beforeUnmount() {
        this.stopStream();
    },
    methods: {
        async getVideoInputDevices() {
            try {
                const devices = await BrowserMultiFormatReader.listVideoInputDevices();
                this.videoInputDevices = devices;
                this.selectedDeviceId = devices.length > 0 ? devices[0].deviceId : null;
                this.initScanner();
            } catch (error) {
                console.error('Error al obtener dispositivos de video:', error);
            }
        },
        stopStream() {
            if (this.activeStream) {
                this.activeStream.getTracks().forEach((track) => track.stop());
                this.activeStream = null;
            }
        },
        async requestCameraPermissions() {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        stream.getTracks().forEach((track) => track.stop()); // Detenemos la cámara después de obtener el permiso
        console.log('Permiso concedido para usar la cámara.');
        return true;
    } catch (error) {
        console.error('Permiso denegado o no se puede acceder a la cámara:', error);
        Swal.fire({
            title: 'Error',
            text: 'Es necesario permitir el uso de la cámara para escanear el código QR.',
            icon: 'error',
            confirmButtonText: 'Ok',
        });
        return false;
    }
},
async initScanner() {
    try {
        const hasPermission = await this.requestCameraPermissions();
        if (!hasPermission) return;

        this.stopStream();

        if (!this.selectedDeviceId) return;

        this.codeReader = new BrowserMultiFormatReader();
        const videoElement = document.getElementById('preview');
        this.activeStream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: this.selectedDeviceId } },
        });

        videoElement.srcObject = this.activeStream;
        videoElement.play();

        this.codeReader.decodeFromVideoElement(videoElement, async (result, error) => {
            if (result) {
                const scannedCode = result.getText();

                if (scannedCode.includes('$')) {
                    const cleanedCode = scannedCode.replace('$', '');
                    this.stopStream(); // Detenemos la cámara para evitar múltiples lecturas
                    this.handleQRResult(cleanedCode);
                }
            }

            if (error && !error.message.includes('No QR code')) {
                console.error('Error al leer el QR:', error);
            }
        });
    } catch (error) {
        console.error('Error al iniciar el escáner:', error);
    }
},
        async handleQRResult(extraCode) {
            this.loading = true;
            try {
                let resp = await fetch(
                    `${process.env.VUE_APP_BACKEND_URL}/api/buscarPedido`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            token: this.user.token,
                            identificador: extraCode,
                        }),
                    }
                );

                if (resp.status === 200) {
                    let user = await resp.json();
                    Swal.fire({
                        title: 'Pedido encontrado',
                        text: 'El pedido ha sido encontrado.',
                        icon: 'success',
                        toast: true,
                        timerProgressBar: true,
                        timer: 3000,
                        position: 'top-end',
                        showConfirmButton: false,
                    });
                    this.$router.push(`/cliente/${user.id}`);
                } else {
                    Swal.fire({
                        title: 'Pedido no encontrado',
                        text: 'El pedido no ha sido encontrado.',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                }
            } catch (error) {
                console.error('Error al buscar el pedido:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error al buscar el pedido.',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
select {
    margin-bottom: 10px;
    padding: 5px;
    font-size: 1rem;
}

.result {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}
</style>
