<template>
    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav" />
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row" v-loading="loading">
                            <div class="col-lg-12">
                                <input v-model="busqueda" type="text" name="busqueda" placeholder="Buscar..."
                                    class="form-control mb-3">
                            </div>
                            <div class="col-md-12 mb-3"><button type="button" @click="crearCliente"
                                    class="btn btn-success btn-block waves-effect waves-light">Nuevo cliente</button>
                            </div>
                            <div class="col-lg-4" v-for="cliente in filteredClientes" :key="cliente">
                                <div @click="irACliente(cliente.id)" class="card profile-card" style="cursor:pointer;">
                                    <div
                                        :class="`card-body ${cliente.pedidospendientes ? 'bg-warning' : 'bg-primary'} p-0`">
                                        <div class="media p-3  align-items-center">
                                            <img src="https://i.imgur.com/8n5W7Px.png" alt="user"
                                                class="rounded-circle thumb-lg">
                                            <div class="media-body ml-3 align-self-center">
                                                <h5 class="mb-1 text-white">{{ cliente.name }}</h5>
                                                <p v-if="cliente.telefono" class="mb-0 font-12 text-white"><i
                                                        class="fa-duotone fa-phone"></i>
                                                    {{ cliente.telefono }}</p>
                                                <p class="mb-0 font-12 text-white">
                                                    {{ cliente.forma_registro ? 'Registro manual' : 'Registro web' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div><!--end card-body-->
                                    <div class="card-body socials-data pb-0 px-0 pt-0">
                                        <div class="row text-center border-top m-0">
                                            <div class="col-6 border-right py-3">
                                                <h3 class="mt-0 mb-1">{{ cliente.pedidospendientes }}</h3>
                                                <span class="font-14 text-muted">Pendientes</span>
                                            </div><!--end col-->
                                            <div class="col-6 border-left py-3">
                                                <h3 class="mt-0 mb-1">{{ cliente.pedidosentregados }}</h3>
                                                <span class="font-14 text-muted">Entregados</span>
                                            </div><!--end col-->
                                        </div><!--end row-->
                                    </div><!--end card-body-->
                                </div><!--end card-->
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!-- container -->

                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with
                            <i class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>

<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
export default {
    name: 'PaginaConfiguracion',
    props: {
        user: Object,
        sidenav: Boolean
    },
    components: {
        Header,
        Sidenav
    },
    computed: {
        filteredClientes() {
            let clientes = [].concat(this.clientes);
            let busqueda = this.busqueda.toLowerCase();
            if (busqueda) {
                clientes = clientes.filter((cliente) => { return cliente.name.toLowerCase().includes(busqueda) || (cliente.telefono && cliente.telefono.includes(busqueda)) })
            }
            return clientes;
        }
    },
    data() {
        return {
            configuracion: '',
            usuario: '',
            loading: true,
            clientes: [],
            busqueda: '',
            nuevoCliente: {
                nombre: '',
                apellido: '',
                telefono: ''
            },
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        async init() {
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerClientes';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token })
                });
                this.clientes = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        irACliente(id) {
            this.$router.push('/cliente/' + id);
        },
        async crearCliente() {
            this.$router.push('/nuevoCliente');

        }
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>