<!-- App.vue -->

<template class="dark">
  <div>
    <div id="app">
      <!-- Barra de navegación u otros elementos globales -->
      <router-view v-loading="loading" :user="user" :onActualizarUser="actualizarUser" :sidenav="activeSide"
        :onCambiarSidenav="actualizarSide" />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      user: '',
      activeSide: true,
      width: window.innerWidth,
      loading: false,
    };
  },

  mounted() {
    console.log(this.$route.path);
    this.user = JSON.parse(localStorage.getItem('usuario'));
    if (this.$route.path != '/login' && (this.user == '' || this.user == null)) {
      this.$router.push('/login');
    }
    if (this.$route.path == '/login' && (this.user != '' && this.user != null)) {
      this.$router.push('/');
    }
    window.addEventListener('resize', this.onResize);
    let barcodeInput = '';
    let timeout;

    // Listener global para capturar la entrada del lector de códigos de barras
    document.addEventListener('keydown', async (event) => {
      // Si se presiona "Enter", se procesa el código escaneado
      if (event.key === 'Enter') {
        if (barcodeInput.includes('$')) {
          this.loading = true;
          // Extraer la parte después del "$"
          const extraCode = barcodeInput.split('$')[1] || '';
          let resp = await fetch(process.env.VUE_APP_BACKEND_URL + '/api/buscarPedido', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: this.user.token, identificador: extraCode })
          });
          if (resp.status == 200) {
            let user = await resp.json();
            Swal.fire({
              title: 'Pedido encontrado',
              text: 'El pedido ha sido encontrado.',
              icon: 'success',
              toast: true,
              timerProgressBar: true,
              timer: 3000,
              position: 'top-end',
              showConfirmButton: false
            })
            this.$router.push('/cliente/' + user.id)
          } else {
            Swal.fire({
              title: 'Pedido no encontrado',
              text: 'El pedido no ha sido encontrado',
              icon: 'error',
              confirmButtonText: 'Ok'
            })
          }
          this.loading = false;
        }
        // Resetear la variable
        barcodeInput = '';
      } else {
        // Agregar la tecla presionada al texto
        barcodeInput += event.key;

        // Resetear después de un tiempo (en caso de input incompleto)
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          barcodeInput = '';
        }, 500); // 500ms de espera
      }
    });
  },
  // En el componente padre
  emits: [
    'actualizarCesta',
    'actualizarDatos'
  ],

  watch: {
    // Observa los cambios en la ruta actual y actualiza el título de la página
    '$route'({ meta }) {
      document.title = meta.title || 'Horno Goimar'; // Título predeterminado si la ruta no tiene título
      if (this.width < 1025) {
        this.activeSide = false;
      }
    }
  },
  methods: {
    onResize() {
      this.width = window.innerWidth
      if (this.width < 1025) {
        this.activeSide = false;
      } else {
        this.activeSide = true;
      }
    },
    actualizarUser(nuevo) {
      this.user = nuevo;
    },
    actualizarSide() {
      this.activeSide = !this.activeSide;
    },
  }
}
</script>

<style lang="scss"></style>