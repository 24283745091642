<template>

    <body>
        <Header :user="user" :onCambiarSidenav="cambiarSidenav" />
        <!-- Top Bar End -->
        <div class="page-wrapper">
            <div class="page-wrapper-inner">
                <!-- Left Sidenav -->
                <Sidenav :sidenav="sidenav" />
                <!-- end left-sidenav-->
                <!-- Page Content-->
                <div class="page-content">
                    <div class="container-fluid">
                        <div class="row" v-loading="loading">
                            <div class="col-md-3">
                                <input v-model="filtros.busqueda" type="text" name="busqueda" placeholder="Buscar..."
                                    class="form-control mb-3 w-100">
                            </div>
                            <div class="col-md-4">
                                <el-date-picker class="w-100" size="large" v-model="filtros.rangofechas" type="daterange" range-separator="hasta"
                                    start-placeholder="Fecha inicio" end-placeholder="Fecha fin" :size="large"
                                    value-format="YYYY-MM-DD" format="DD/MM/YYYY" />
                            </div>
                            <div class="col-md-3">
                                <el-select size="large" class="w-100" v-model="filtros.empleado" placeholder="Empleado asignado">
                                    <el-option :value="null" label="Cualquier empleado"></el-option>
                                    <el-option v-for="empleado in empleados" :value="empleado.user" :label="empleado.name"></el-option>
                                </el-select>
                            </div>
                            <div class="col-lg-12" v-for="pedido in filteredPedidos" :key="pedido">
                                <div @click="irACliente(pedido.cliente.id)" class="card profile-card"
                                    style="cursor:pointer;">
                                    <div :class="`card-body ${pedido.entregado ? 'bg-success' : 'bg-warning'} p-0`">
                                        <div class="media p-3  align-items-center">
                                            <div class="media-body align-self-center">
                                                <h5 class="mb-1 text-white mt0">{{ pedido.fechaFormateada }} | {{
                                                    pedido.cliente.name }}</h5>
                                                <p class="mb-0 font-12 text-white" v-if="pedido.entregado"><i
                                                        class="fa-duotone fa-check"></i> Entregado</p>
                                                <p class="mb-0 font-12 text-white" v-else><i
                                                        class="fa-duotone fa-solid fa-hourglass-start"></i> Pendiente de
                                                    entrega</p>
                                            </div>
                                        </div>
                                    </div><!--end card-body-->
                                </div><!--end card-->
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!-- container -->

                    <footer class="footer text-center text-sm-left">
                        &copy; 2019 Frogetor <span class="text-muted d-none d-sm-inline-block float-right">Crafted with
                            <i class="mdi mdi-heart text-danger"></i> by Mannatthemes</span>
                    </footer>
                </div>
                <!-- end page content -->
            </div>
            <!--end page-wrapper-inner -->
        </div>
        <!-- end page-wrapper -->
    </body>
</template>

<script>
import Header from './Header.vue'
import Sidenav from './Sidenav.vue'
import moment from 'moment'
export default {
    name: 'PaginaPedidos',
    props: {
        user: Object,
        sidenav: Boolean
    },
    components: {
        Header,
        Sidenav
    },
    computed: {
        filteredPedidos() {
            let pedidos = [].concat(this.pedidos);
            let busqueda = this.filtros.busqueda.toLowerCase();
            if (busqueda) {
                pedidos = pedidos.filter((pedido) => { return pedido.cliente.name.toLowerCase().includes(busqueda) || (pedido.cliente.telefono && pedido.cliente.telefono.includes(busqueda)) })
            }
            if (this.filtros.rangofechas.length > 0) {
                pedidos = pedidos.filter((pendiente) =>
                    pendiente.fecha >= this.filtros.rangofechas[0] &&
                    pendiente.fecha <= this.filtros.rangofechas[1]
                );
            }
            if (this.filtros.empleado) {
                pedidos = pedidos.filter((pedido) => { return pedido.empleado_asignado == this.filtros.empleado })
            }
            return pedidos;
        }
    },
    data() {
        return {
            configuracion: '',
            usuario: '',
            loading: true,
            pedidos: [],
            empleados: [],
            filtros: {
                busqueda: '',
                rangofechas: [
                    moment().format('YYYY-MM-DD'),
                    moment().format('YYYY-MM-DD'),
                ],
                empleado: null,
            }
        }
    },
    mounted() {
        this.init();
        this.usuario = this.user
    },

    methods: {
        cambiarSidenav() {
            this.$emit('cambiarSidenav');
        },
        async init() {
            const url = process.env.VUE_APP_BACKEND_URL + '/api/obtenerPedidos';

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token })
                });
                this.pedidos = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }

            const url2 = process.env.VUE_APP_BACKEND_URL + '/api/obtenerEmpleados';

            try {
                const response = await fetch(url2, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: this.user.token })
                });
                this.empleados = await response.json();
                this.loading = false;
            } catch (error) {
                console.error('Error en la solicitud:', error.message);
            }
        },
        irACliente(id) {
            this.$router.push('/cliente/' + id);
        },
    }
}
</script>

<style lang="scss" scoped>
#contenedorInicio {
    display: flex;
    justify-content: center;
    align-items: center;

    .texto {
        text-align: center;
        color: white;
        max-width: 75%;

        img {
            width: 30%;
        }

        h1 {
            margin-bottom: 5px;
        }

        h3 {
            margin-top: 0px;
            font-weight: normal;
        }

        button {
            font-size: 1.5rem;
            padding: 20px;

            i {
                margin-right: 10px;
            }
        }
    }
}
</style>